import { Button, Container } from 'components/ui'
import React from 'react'
import useHomeContext from '../services/homeContext'
import { useTranslation } from 'react-i18next'
import { Flex } from 'antd'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCards } from 'swiper/modules'
import 'swiper/css/effect-cards'
import { Link, useNavigate } from 'react-router-dom'
import useConverter from 'hooks/useConverter'
import useLayoutContext from 'components/ui/layout/services/layoutContext'
import { format } from 'utils/format'

const Collection = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const language = i18n.language as 'uz' | 'ru' | 'en'
  const { converter } = useConverter()
  const {
    state: { data }
  } = useHomeContext()

  const {
    state: { currency }
  } = useLayoutContext()

  return data ? (
    <div
      className="collection"
      style={{
        backgroundImage: `url(${process.env.REACT_APP_FILE_URL}${data?.collection_background?.source_url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <Container size="xxl">
        <Flex wrap justify="space-between" gap={50}>
          <div>
            <h1 className="cormorant-title" style={{ marginBottom: 20 }}>
              {data.collection_name?.[language]}
            </h1>
            {/* <Button
              type="contained"
              onClick={() =>
                navigate(`categories/${data.collection_direction}`)
              }
            >
              <span>{t('home.collections.button')}</span>
            </Button> */}
          </div>
          <div className="collection-swiper-wrapper">
            <Swiper
              effect={'cards'}
              grabCursor={true}
              modules={[EffectCards]}
              className="collection-swiper"
              style={{
                width: '330px',
                height: '450px',
                overflow: 'visible',
                maxWidth: '100%'
              }}
            >
              {data.collections.map((collection) => (
                <SwiperSlide
                  className="collection-swiper-slide"
                  onClick={() => navigate(`product/${collection._id}`)}
                >
                  <img
                    src={`${process.env.REACT_APP_FILE_URL}${collection?.main_image?.source_url}`}
                    alt={collection?.main_image?.alt}
                  />
                  <div>
                    <p>{collection.name[language]}</p>{' '}
                    <p>
                      {format.money(
                        converter(
                          collection.integration.price,
                          collection.integration.currency_id,
                          currency
                        ),
                        currency
                      )}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Flex>
      </Container>
    </div>
  ) : null
}

export default Collection
